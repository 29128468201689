<template>
  <a-form :layout="'vertical'">
    <a-form-item
      :validateStatus="checkingInputs.statusSubtagsId"
      :help="checkingInputs.helpSubtagsId"
    >
      <div class="create-tender-label">Выберите тип тендера*</div>
      <a-select
        placeholder="Профиль работ"
        class="a-form-item-selec"
        size="large"
        @change="updateSubtagsId"
        showArrow
        mode="multiple"
        :default-value="getCurrentWorkTagsValue()"
      >
        <a-select-option v-for="i in getWorkTagsValue()" :key="i.id">
          {{ i.name }}
        </a-select-option>
      </a-select>
    </a-form-item>

    <a-form-item
      :validateStatus="checkingInputs.statusName"
      :help="checkingInputs.helpName"
    >
      <div class="create-tender-label">Что требуется выполнить?*</div>
      <a-input
        class="a-form-item-input"
        :value="form.name"
        v-on:input="updateNameTender($event)"
        placeholder="Название проекта"
      />
    </a-form-item>
    <a-form-item
      :validateStatus="checkingInputs.statusTextDetail"
      :help="checkingInputs.helpTextDestail"
    >
      <div class="create-tender-label">Расскажите о задаче подробнее*</div>
      <a-textarea
        :value="form.detailText"
        v-on:input="updateDetailText($event)"
        placeholder="Укажите объём и виды работ"
        :auto-size="{ minRows: 3, maxRows: 5 }"
        class="redact__tender__text__area"
      />
    </a-form-item>
    <a-form-item>
      <div class="create-tender-label">Вложите файлы</div>
      <div>
        Если у вас есть готовое задание, фото или видео материалы, обязательно
        прикрепите их сюда. Исполнителм лучше оценят вашу задачу и сфрмулируют
        своё предложение.
      </div>
    </a-form-item>

    <a-form-item>
      <div class="clearfix">
        <a-upload
          listType="picture-card"
          :fileList="fileList"
          @preview="handlePreview"
          @change="handleChange"
          :before-upload="beforeUpload"
        >
          <div v-if="fileList && fileList.length && fileList.length < 5">
            <a-icon type="plus" />
            <div class="ant-upload-text">Upload</div>
          </div>
        </a-upload>
        <a-modal
          :visible="previewVisible"
          :footer="null"
          @cancel="handleCancel"
        >
          <img alt="example" style="width: 100%" :src="previewImage" />
        </a-modal>
      </div>
    </a-form-item>

    <a-form-item
      :validateStatus="checkingInputs.statusCityName"
      :help="checkingInputs.helpCityName"
    >
      <div class="create-tender-label">Место проведения работ</div>
      <a-select
          show-search
          :placeholder="form.address"
          label-in-value
          option-filter-prop="children"
          size="large"
      >
        <a-select-option v-for="i in getCities()" :key="i.name" @click="updateAddress(i.name)">
          {{ i.name }}
        </a-select-option>
      </a-select>
    </a-form-item>
    </a-form-item>

    <a-form-item style="margin-bottom: 0px;">
      <div class="create-tender-label">Ориентировочный бюджет*</div>
      <a-form-item>
         <div class="redact__tender__input__money__container">
        <a-form-item
          :validateStatus="checkingInputs.statusStartMoney"
          :help="checkingInputs.helpStartMoney"
          class="redact__tender__input__money__item"
        >
          <div class="input-money-containder">
            <div class="input-money-previe-text">от</div>
            <a-input
              class="a-form-item-input"
              :value="form.planned_start_price"
              v-on:input="updatePlannedStartPrice($event)"
              @keypress="validateNumber"
              size="large"
            >
              <template v-slot:suffix>RUB</template>
            </a-input>
          </div>
        </a-form-item>
          <span
          :style="{
            display: 'inline-block',
            width: '24px',
            textAlign: 'center',
          }"
          ></span>
          <a-form-item
            :validateStatus="checkingInputs.statusEndMoney"
            :help="checkingInputs.helpEndMoney"
            class="redact__tender__input__money__item"
          >
          <div class="input-money-containder">
            <div class="input-money-previe-text">до</div>
              <a-input
                class="a-form-item-input"
                :value="form.planned_end_price"
                v-on:input="updatePlannedEndPrice($event)"
                @keypress="validateNumber"
                size="large"
              >
              <template v-slot:suffix>RUB</template>
              </a-input>
            </div>
          </a-form-item>
        </div>
      </a-form-item>
    </a-form-item>
    <a-form-item
      :validateStatus="checkingInputs.statusDate"
      :help="checkingInputs.helpDate"
    >
      <div class="create-tender-label">Срок выполнения работ*</div>
      <a-range-picker
        :placeholder="['Начать работу', 'Завершить работу']"
        :value="form.moment"
        @change="chooseDate"
        format="YYYY-MM-DD"
        :disabled-date="disabledDate"
        size="large"
      />
    </a-form-item>
  </a-form>
</template>

<script>
import Button from '@/components/common/Button.vue'

import axios from 'axios'
import {previewImage} from "ant-design-vue/lib/upload/utils";

export default {
  props: ['draftTender', 'checkingInputs'],
  components: [Button],

  mounted () {
    this.$store.dispatch('setTags', this.$store.getters.getTags)
    this.$store.dispatch('setCities', true)
  },

  data () {
    return {
      fileList: this.getImage(this.draftTender.files),
      form: this.draftTender,
      checkingForm: this.checkingInputs,
      statusDetailText: 'success',
      helpDetailText: '',
      bordered: false,
      defAddres: 'Москва',
      i: 0,
      sizeArray: [],
      previewVisible: false
    }
  },

  methods: {
    previewImage,
    getCities () {
      return this.$store.getters.getCities
    },
    filterOption (input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    getImage (imageNames) {
      let arr = []
      let i = 0
      if (imageNames) {
        for (let value of imageNames) {
          const file = {
            uid: i,
            name: value,
            status: 'done',
            url: process.env.VUE_APP_SERVER_URL_FILE + value
          }

          arr.push(file)
          i += 1
        }
      }
      return arr
    },
    handleCancel () {
      this.previewVisible = false
    },
    handlePreview (file) {
      this.previewImage = file.url || file.thumbUrl
      this.previewVisible = true
    },
    handleChange ({ file, fileList }) {
      if (file.size === 2000000) {
        this.$notification.open({
          message: 'Файл больше разрешеного размера'
        })
        return
      }
      if (file.status === 'removed') {
        const newImages = this.form.files.filter((image) => {
          if (image !== file.name) {
            return image
          }
        })
        this.form.files = newImages
        this.fileList = fileList
        this.updateRemoveCommonSize(file)
      } else {
        if (this.getSummFiles() + file.size < 20000000) {
          this.submitFile(file)
        } else {
          this.$notification.open({
            message: 'Общий размер файлов больше чем возможно, удалите файл'
          })
        }
      }
    },
    beforeUpload () {
      return false
    },
    submitFile (file) {
      let formData = new FormData()

      formData.append('images[' + 0 + ']', file)

      axios
        .post(process.env.VUE_APP_SERVER_URL + '/images', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((res) => {
          if (this.form.files) {
            this.form.files = [...this.form.files, res.data.data[0]]
            this.fileList = this.getImage(this.form.files)
            // this.form.files.append(res.data.data[0]);
          } else {
            this.form.files = [res.data.data[0]]
            this.fileList = this.getImage(this.form.files)
          }
          this.updateAddCommonSize(file, res.data.data[0])
        })
        .catch(function () {
          console.log('FAILURE!!')
        })
    },
    validateNumber (e) {
      let keyCode = e.keyCode ? e.keyCode : e.which
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        e.preventDefault()
      }
    },
    updateAddCommonSize (file, name) {
      this.sizeArray.push({
        name: name,
        size: file.size
      })
    },
    updateRemoveCommonSize (file) {
      const newCommonSize = this.sizeArray.filter((item) => item.name !== file.name)
      this.sizeArray = newCommonSize
    },
    getSummFiles () {
      let sum = 0
      for (let i of this.sizeArray) {
        sum += i.size
      }
      return sum
    },
    disabledDate (current) {
      // Can not select days before today and today
      return current && current.valueOf() < Date.now()
    },
    getCurrentWorkTagsValue () {
      let arr = []
      if (this.draftTender.subtagsId) {
        this.draftTender.subtagsId.map((profile) => {
          arr.push(profile)
        })
      }
      return arr
    },
    chooseDate (value, dateString) {
      this.form.moment = value
      this.form.planned_start_date = dateString[0]
      this.form.planned_end_date = dateString[1]
      this.checkingForm.statusDate = 'success'
      this.checkingForm.helpDate = ''
    },

    transferCreateTenderForm: function () {
      this.$emit('transferCreateTenderForm', this.form)
    },
    transferCheckingTenderForm () {
      this.$emit('transferCheckingTenderForm', this.form)
    },
    getWorkTagsValue () {
      let arr = []
      this.$store.getters.getSubTags.map((subtag) => {
          arr.push(subtag)
      })

      return arr
    },

    updateNameTender (e) {
      this.form.name = e.target.value
      this.checkingForm.statusName = 'success'
      this.checkingForm.helpName = ''
    },
    updateDetailText (e) {
      this.form.detailText = e.target.value
      this.checkingForm.statusTextDetail = 'success'
      this.checkingForm.helpTextDestail = ''
    },
    updateSubtagsId (value) {
      this.form.subtagsId = value
      this.checkingForm.statusSubtagsId = 'success'
      this.checkingForm.helpSubtagsId = ''
    },

    updatePlannedStartPrice (e) {
      this.form.planned_start_price = e.target.value
      this.checkingForm.statusStartMoney = 'success'
      this.checkingForm.helpStartMoney = ''
      this.checkingForm.statusEndMoney = 'success'
      this.checkingForm.helpEndMoney = ''
    },
    updatePlannedEndPrice (e) {
      this.form.planned_end_price = e.target.value
      this.checkingForm.statusStartMoney = 'success'
      this.checkingForm.helpStartMoney = ''
      this.checkingForm.statusEndMoney = 'success'
      this.checkingForm.helpEndMoney = ''
    },
    updateAddress (value) {
      this.form.address = value
      this.checkingForm.statusCityName = 'success'
      this.checkingForm.helpCityName = ''
    },

    toushCityNameFromArray (name) {
      this.inputTextCityName = name
      this.checkingForm.statusCityName = 'success'
      this.checkingForm.helpCityName = ''
    },

    filterArray () {
      const constCities = this.$store.getters.getCities
      if (this.inputTextCityName === '') {
        return constCities
      } else {
        const filterCities = this.$store.getters.getCities.filter((city) =>
          city.name.match(this.inputTextCityName)
        )
        return filterCities
      }
    }
  }
}

//  if (!this.draftTender.subtagsId) {
//       this.checkingInputs.statusSubtagsId = "error";
//       this.checkingInputs.helpSubtagsId =
//         "Вы не выбрали ни одного направления";
//     } else {
//       console.log("this.draftTender.subtagsId.length == 0");
//       if (this.draftTender.subtagsId.length == 0) {
//         this.checkingInputs.statusSubtagsId = "error";
//         this.checkingInputs.helpSubtagsId =
//           "Выберите хотя бы одно направление";
//       }
</script>
<style lang="scss">
.ant-select-selection-placeholder {
  color: black;
}

.redact__tender__input__money {
  &__container{
    display: flex;
    flex-direction: row;
  }
  &__item{
    display: inline-block;
    width: calc(50% - 12px);
  }
}
.description-tender-image {
  max-width: 285px;
  max-height: 174px;
  object-fit: cover;
  margin-right: 20px;
  margin-bottom: 20px;
}
.input-money-form-container {
  width: 80%;
  height: 40px;
}

.input-money-containder {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: center;
  height: 40px;
}

.input-money-previe-text {
  margin-right: 12px;
}
.unBordered-input {
  border: none;
}
.redact__tender__text__area {
  resize: none;
}

/* .upload-container {
  height: 40px;
  width: 100%;
  border: 1px solid #b12021;
  color: #b12021;

  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
} */

.upload-project {
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.upload-text {
  color: #b12021;
}

.ant-upload {
  width: 100%;
  height: 100%;

  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.upload-image {
  margin-right: 11px;
}

.create-tender-label {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: black;
  margin-bottom: 17px;
}

.a-form-item-input {
  height: 40px;
}

@media (max-width: 720px) {
.redact__tender__input__money {
  &__container{
    flex-direction: column;
    margin-bottom: 0px;
  }
  &__item{
    display: inline-block;
    width: 80%;
  }
}
}
</style>
