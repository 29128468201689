<template>
  <div class="tender__description">
         <div class="tender__description__date__and__price__container">
          <div
            v-if="draftTender.updated_at"
            class="tender__description__date"
          >
            {{ draftTender.updated_at }}
          </div>
          <div class="tender__description__date">
            с {{ draftTender.planned_start_date }} по
            {{ draftTender.planned_end_date }}
          </div>
          <div class="tender__description__price">
            от {{ draftTender.planned_start_price }} руб. до
            {{ draftTender.planned_end_price }} руб.
          </div>
        </div>

        <div class="tender__description__name__and__status__container">
          <div class="tender__description__title">{{ draftTender.name }}</div>
          <div
            v-if="draftTender.status"
            class="tender__description__pre-word-status"
          >
            статус:
            <span
              :style="{
                display: 'inline-block',
                width: '14px',
                textAlign: 'center',
              }"
            ></span>
            <div
              :class="`tender__description__status__${draftTender.status.id}`"
            >
              {{ draftTender.status.name }}
            </div>
          </div>
        </div>
        <div v-if="draftTender.files &&  draftTender.files.length != 0">
          <div class="tender__description__title">Файлы к тендеру</div>
          <div class="tender__description__description">
            Файлы для скачивания будут доступны исполнителям с базовым аккаунтом
            после отклика.
          </div>
          <FilesBig
            class="tender__description__image"
            :files="draftTender.files"
            size=""
            :notRedact="true"
          />
        </div>
        <div v-if="draftTender.files && draftTender.files.length == 0">
          <div class="tender__description__title">У тендера нет файлов для просмотра</div>
        </div>
        <div class="tender__description__title">Требуемые услуги</div>
        <div class="tender__description__services-work">
          <ServiceWork
            v-for="subtag in getDraftTenderSubtags()"
            :key="subtag.subtagId"
            :name="subtag.name"
            :color="subtag.color"
          />
        </div>
        <div class="tender__description__title">
          Город исполнителя, регион (желательно)
        </div>
        <div class="tender__description__description">
          {{ draftTender.address }}
        </div>

        <div class="tender__description__title">Дополнительно</div>
        <div class="tender__description__description">
          – {{ draftTender.detailText }}
        </div>
    <div class="tender__description__button__container"></div>

    <div v-if="!isCreate">
      <div
        v-if="isCurrentUser() && isCustomer"
        class="tender-description-redact-buttons"
      >

        <router-link
            v-if="draftTender.statusId == 1"
            class="customer__tender__description__one__button"
            :to="`/tender/redact/${draftTender.uuid}`"
        >
          <Button  style="height: 40px" buttonClass="white-antd-button-without-width">
            Редактировать
          </Button>
        </router-link>
        <a
          v-if="isCustomerRedact"

        >
          <Button
            v-if="draftTender.statusId == 1"
            buttonClass="white-antd-button-without-width"
          >
            Редактировать
          </Button>
        </a>
        <Button
          v-if="draftTender.statusId != 1"
          class="white-antd-button-without-width"
          >В архив</Button
        >
        <span
          :style="{
            display: 'inline-block',
            width: '24px',
            textAlign: 'center',
          }"
        ></span>

        <Button
          v-if="draftTender.statusId != 1"
          @click="showReviewModal"
          class="red-antd-button-without-width"
          >Оставить Отзыв</Button
        >
        <Button
          v-if="isCustomerRedact && draftTender.status.id != 3"
          @click="deactivateTender"
          buttonClass="red-antd-button-without-width"
        >
          Завершить
        </Button>
      </div>

      <div v-if="isMaster">
        <div @click="showOrderModal">
          <Button buttonClass="red-antd-button-without-width">
            Откликнуться
          </Button>
        </div>
      </div>
    </div>
    <a-modal
      :footer="null"
      :visible="reviewVisible"
      @cancel="cancelReviewModal"
    >
      <!-- // todo -->
      <ReviewModal
        :image="draftTender"
        :userUUID="draftTender.userId"
        :winnerId="draftTender.winnerId"
        @okButtonReviewModule="reviewVisibleOk"
      ></ReviewModal>
    </a-modal>
    <a-modal :footer="null" :visible="orderVisible" @cancel="cancelOrderModal">
      <OrderRespondModal
        :tenderUUID="draftTender.uuid"
        @okButtonOrderModule="orderVisibleOk"
      ></OrderRespondModal>
    </a-modal>
  </div>
</template>

<script>
import ServiceWork from '@/components/common/ServiceWork.vue'
import OrderRespondModal from '@/components/modal/OrderRespondModal.vue'
import ReviewModal from '@/components/modal/ReviewModal.vue'
import Button from '@/components/common/Button.vue'
import FilesBig from '@/components/common/FilesBig'
import { getBearer, getUUID, isCustomer, isMaster } from '@/helpers/authHelper'

export default {
  props: [
    'draftTender',
    'isCustomerRedact',
    'isRedact',
    'isCreate',
    'subTags',
    'isCurrentMasterTender'
  ],
  components: {
    ServiceWork,
    OrderRespondModal,
    Button,
    ReviewModal,
    FilesBig
  },
  data () {
    return {
      reviewVisible: false,
      orderVisible: false,
      link: 'RedactTender',
      customUrl: process.env.VUE_APP_SERVER_URL,
      isMaster: isMaster(),
      isCustomer: isCustomer(),
      getUUID: getUUID()
    }
  },
  methods: {
    getDraftTenderSubtags () {
      const filterArr = this.subTags.filter((subtag) => {
        for (let id of this.draftTender.subtagsId) {
          if (id === subtag.id) {
            return subtag
          }
        }
      })

      return filterArr
    },
    isCurrentUser () {
      if (this.draftTender.customer.uuid === getUUID()) {
        return true
      } else {
        return false
      }
    },
    click () {
    },
    showReviewModal () {
      this.reviewVisible = true
    },
    showOrderModal () {
      this.orderVisible = true
    },
    reviewVisibleOk () {
      this.reviewVisible = false
    },
    orderVisibleOk () {
      this.orderVisible = false
    },
    cancelOrderModal () {
      this.orderVisible = false
    },
    cancelReviewModal () {
      this.reviewVisible = false
    },
    deactivateTender () {
      const url = process.env.VUE_APP_SERVER_URL + `/tenders/${this.draftTender.uuid}`
      fetch(url, {
        method: 'PUT',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: getBearer()
        },
        body: JSON.stringify({ statusId: 3 })
      })
        .then((response) => response.json())
        .then((res) => {
          this.$notification.open({
            message: 'Вы завершили тендер!',
            description: res.message
          })
        })

        .catch((error) => {
          this.$notification.open({
            message: 'Вы не смогли завершить тендер!',
            description: error
          })
        })
    }
  }
}
</script>

<style lang="scss">
.tender__description {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;

  background-color: white;
  border-radius: 3px;
  &__date__and__price__container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  &__date {
    color: #989898;
    text-align: left;
  }

  &__price {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: right;
    color: black;
  }
  &__image {
    margin-top: -45px;
    margin-bottom: 60px;
  }

  &__title__and__status__container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__title {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    color: black;
    margin-bottom: 30px;
  }

  &__pre-word-status {
    display: flex;
    flex-direction: row;
  }

  &__status {
    &__1 {
      color: #7fb56c;
    }
    &__2 {
      color: #b5b46c;
    }
    &__3 {
      color: #fa491c;
    }
  }

  &__services-work {
    margin-top: -25px;
    margin-bottom: 30px;
  }

  &__description {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    padding-right: 35%;
    margin-bottom: 20px;
    margin-top: -25px;
  }
  &__buttons__container {
    display: flex;
    justify-content: flex-start;
  }
  &__button {
    margin-right: 12px;
  }
}
@media (max-width: 1200px) {
  .tender__description {
    &__date__and__price__container {
      flex-direction: column;
      justify-content: flex-start;
    }
    &__title {
      margin-top: 30px;
      margin-bottom: 15px;
    }
    &__title__and__status__container {
      flex-direction: column;
      justify-content: flex-start;
    }
    &__services-work {
      margin-top: 0px;
      margin-bottom: 0px;
    }
    &__description {
      margin-top: 0px;
    }
  }
}
</style>
